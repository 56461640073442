import { FormInstance } from 'antd';
import { NamePath } from 'antd/lib/form/interface';
import ContentStore from '../stores/ContentStore';
import { ProductType } from '../swagger/api';

const validateOnlyNumbersAndExactEight = (form: FormInstance) => {
  if (!form.getFieldValue('phoneNumber').match('^[0-9]{8}$')) {
    return Promise.reject(new Error(''));
  }
  return Promise.resolve();
};

export const validateIsDanishLicensePlate = (licensePlateInput: string) =>
  licensePlateInput.match('^([A-Za-z]{2})(\\d{2})(\\d{3})$');

export const validateIsSwedishLicensePlate = (licensePlateInput: string) =>
  licensePlateInput.match('^[A-Za-z]{3}[0-9]{2}[A-Za-z0-9]{1}$');

export const validateOnlyNumbers = (value: string) => {
  if (value !== '' && !value.match('^[0-9]+$')) {
    return Promise.reject(new Error(''));
  }
  return Promise.resolve();
};

export const isValidEmailFormat = (value: string) => {
  const emailRegex = '^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+\\.[a-zA-Z0-9-.]+$';
  return value.match(emailRegex);
};

export const validateIsEmailValid = async (email: string) => {
  if (isValidEmailFormat(email)) {
    return Promise.resolve();
  }
  return Promise.reject();
};

export const validateDanishPhoneNumber = async (
  _: any,
  value: any,
  form: FormInstance
  // eslint-disable-next-line consistent-return
) => {
  if (value !== '' && form.getFieldValue('phonePrefix') === '+45') {
    return validateOnlyNumbersAndExactEight(form);
  }
};

export const validateForeignPhoneNumber = async (
  _: any,
  value: any,
  form: FormInstance
  // eslint-disable-next-line consistent-return
) => {
  if (value !== '' && form.getFieldValue('phonePrefix') !== '+45') {
    return validateOnlyNumbers(form.getFieldValue('phoneNumber'));
  }
};

export const getIsChassisNumberMandatory = (
  contentStore: ContentStore,
  form: FormInstance,
  countryIdFormItemName: NamePath | string,
  productType: ProductType,
  key?: number
) => {
  const isApprovedCountry = contentStore.genericContentStore.getApprovedCountriesIsoCodes.includes(
    form.getFieldValue(countryIdFormItemName)
  );

  // chassisNumber is mandatory ONLY when documentation is uploaded (which is possible ONLY for non-approved countries adn not OneTimeANPR).
  let isMandatory = false;
  if (!isApprovedCountry && productType !== ProductType.OneTimeANPR) {
    if (key !== undefined) {
      isMandatory = !!form.getFieldValue([key, 'encodedDocumentPdf']);
    } else {
      isMandatory = !!(
        form.getFieldValue('encodedDocumentPdf') || form.getFieldValue('vehicleDocumentId')
      );
    }
  }
  return isMandatory;
};

export const validateChassisNumber = (
  contentStore: ContentStore,
  form: FormInstance,
  countryIdFormItemName: NamePath | string,
  requiredFieldErrorMessage: string,
  productType: ProductType,
  key?: number
) => {
  const vinFormItemName = key || key === 0 ? [key, 'vin'] : 'vin';
  if (
    getIsChassisNumberMandatory(contentStore, form, countryIdFormItemName, productType, key) &&
    (!form.getFieldValue(vinFormItemName) || !form.getFieldValue(vinFormItemName).trim())
  )
    return Promise.reject(new Error(requiredFieldErrorMessage));
  return Promise.resolve();
};
